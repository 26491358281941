import {
    ADD_TODO,
    DELETE_TODO,
    TODO_FILTER_COMPLETE,
    TODO_FILTER_INCOMPLETE,
    TODO_REMOVE_FILTERS,
    TodoActionTypes,
    TOGGLE_COMPLETE_TODO
} from "./todo.actions";

export interface TodoState {
    items: TodoItem[];
    filter: null | ((item: TodoItem) => boolean);
}

export interface TodoItem {
    name: string;
    priority: number;
    completed: boolean;
}

const todoInitialState: TodoState = {
    items: [],
    filter: null
};

export const TodoReducer = (
    state = todoInitialState,
    action: TodoActionTypes): TodoState => {
    switch (action.type) {
        case ADD_TODO:
            return {
                items: state.items.concat({
                    name: action.name,
                    priority: state.items.length,
                    completed: false
                }),
                filter: state.filter
            };
        case DELETE_TODO:
            return {
                items: state.items.filter(item => item.priority !== action.item.priority),
                filter: state.filter
            };
        case TOGGLE_COMPLETE_TODO:
            const updatedItems = state.items.map(item => {
                if (item.priority === action.priority) {
                    return {
                        name: item.name,
                        priority: item.priority,
                        completed: action.complete
                    };
                } else {
                    return item;
                }
            });
            return {
                items: updatedItems,
                filter: state.filter
            };
        case TODO_FILTER_COMPLETE:
            return {
                items: state.items,
                filter: (item: TodoItem) => item.completed
            };
        case TODO_FILTER_INCOMPLETE:
            return {
                items: state.items,
                filter: (item: TodoItem) => !item.completed
            };
        case TODO_REMOVE_FILTERS:
            return {
                items: state.items,
                filter: null
            };
        default:
            return state;
    }
};
