import {combineReducers, createStore} from "redux";
import {TodoReducer} from "./components/todo/todo.reducers";

const rootReducer = combineReducers({
    todo: TodoReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export const appStore = createStore(
    rootReducer,
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__()
);
