import React from 'react';
import './App.css';
import {Todo} from "./components/todo/todo";
import {TodoHeader} from "./components/todoheader/todo-header";

function App() {
  return (
    <div className="App">
        <TodoHeader />
        <Todo />
    </div>
  );
}

export default App;
