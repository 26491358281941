import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store";
import {TodoItem, TodoState} from "./todo.reducers";
import React, {FormEvent} from "react";
import {ADD_TODO, DELETE_TODO, TodoActionTypes, TOGGLE_COMPLETE_TODO} from "./todo.actions";
import {Dispatch} from "redux";

export function Todo() {
    const dispatch = useDispatch();
    const todoState = useSelector((state: RootState): TodoState => state.todo);
    let items;
    if (todoState.filter) {
        items = todoState.items
            .sort((item: TodoItem) => item.priority)
            .filter(todoState.filter)
            .map((item: TodoItem) => RenderItem(item, dispatch));
    } else {
        items = todoState.items.sort((item: TodoItem) => item.priority)
            .map(item => RenderItem(item, dispatch));
    }

    return (
        <div>
            <div>
                {noItemsMessage(!!items.length)}
            </div>
            <ol>
                {items}
            </ol>
            <div>
                <form onSubmit={(event) => addTodo(event, dispatch)}>
                    <label htmlFor="addItemName">To do item</label>
                    <input type="text" id="addItemName"/>
                    <button type="submit">Add Item</button>
                </form>
            </div>
        </div>
    );
}

function addTodo(event: FormEvent, dispatch: Dispatch<TodoActionTypes>): void {
    event.preventDefault();
    const addItemInput: HTMLInputElement | null = document.querySelector('#addItemName');
    if (!addItemInput) {
        return;
    }
    const itemName = addItemInput.value;
    if (itemName) {
        const action: TodoActionTypes = {
            type: ADD_TODO,
            name: itemName
        }
        dispatch(action);
        addItemInput.value = '';
    }
}

function RenderItem(props: TodoItem, dispatch: any) {
    const deleteAction: TodoActionTypes = {
        type: DELETE_TODO,
        item: props
    }

    function toggleComplete() {
        const input: HTMLInputElement | null = document.querySelector(`input#complete${props.priority}`);
        const checked = input?.checked;
        dispatch({
            type: TOGGLE_COMPLETE_TODO,
            complete: checked,
            priority: props.priority
        });
    }

    return (
        <div key={props.priority}>
            <input type="checkbox" checked={props.completed} onChange={toggleComplete.bind(props, dispatch)} id={`complete${props.priority}`} />
            <label htmlFor={`complete${props.priority}`}>{props.name}</label>
            <button onClick={() => dispatch(deleteAction)}>Delete</button>
        </div>
    )
}

function noItemsMessage(hasItems: boolean) {
    if (!hasItems) {
        return (<h1>No items added to do</h1>);
    } else {
        return null;
    }
}
