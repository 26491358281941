import {TodoItem} from "./todo.reducers";

export const ADD_TODO = 'Todo/Add';
export const DELETE_TODO = 'Todo/Delete';
export const TOGGLE_COMPLETE_TODO = 'Todo/Complete';
export const TODO_FILTER_COMPLETE = 'Todo/FilterComplete';
export const TODO_FILTER_INCOMPLETE = 'Todo/FilterIncomplete';
export const TODO_REMOVE_FILTERS = 'Todo/RemoveFilters';

interface TodoAdd {
    type: typeof ADD_TODO;
    name: string;
}

interface TodoDelete {
    type: typeof DELETE_TODO;
    item: TodoItem;
}

interface TodoComplete {
    type: typeof TOGGLE_COMPLETE_TODO;
    complete: boolean;
    priority: number;
}

interface TodoFilterComplete {
    type: typeof TODO_FILTER_COMPLETE
}

interface TodoFilterIncomplete {
    type: typeof TODO_FILTER_INCOMPLETE
}

interface TodoClearFilters {
    type: typeof TODO_REMOVE_FILTERS
}

export type TodoActionTypes = TodoAdd | TodoDelete | TodoComplete | TodoFilterComplete | TodoFilterIncomplete | TodoClearFilters;
