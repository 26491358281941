import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store";
import {TODO_FILTER_COMPLETE, TODO_FILTER_INCOMPLETE, TODO_REMOVE_FILTERS} from "../todo/todo.actions";

export function TodoHeader() {
    const dispatch = useDispatch();
    const items = useSelector((state: RootState) => state.todo.items);
    const completed = items.filter(item => item.completed).length;
    const incomplete = items.length - completed;

    function filter() {
        const filter: HTMLSelectElement | null = document.querySelector('#todoFilter');
        const appliedFilter = filter?.value;
        dispatch({ type: appliedFilter });
    }

    return (
        <div>
            <div>
                <b>{completed} items complete</b>
            </div>
            <div>
                <b>{incomplete} items left to complete</b>
            </div>
            <div>
                <label>Filters:</label>
                <select id="todoFilter" onChange={() => filter()}>
                    <option value={TODO_REMOVE_FILTERS}>None</option>
                    <option value={TODO_FILTER_COMPLETE}>Complete</option>
                    <option value={TODO_FILTER_INCOMPLETE}>Incomplete</option>
                </select>
            </div>
        </div>
    )
}
